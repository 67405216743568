import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import Slider from "react-slick";

function TextGrid() {
  let pageHeader = React.createRef();
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="py-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col className="mx-auto" md={12}>
              <h1 className="title mb-4 section-title text-center">
                <b style={{ color: '#57c8e8' }}>Event Overview</b>
              </h1>
              <br />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <h3 className="text-white">
                <b>
                  In the context of the UAE, digital transformation is a national priority,
                  making cybersecurity crucial for maintaining organizational integrity
                  and data privacy.
                  <br />
                  <br />
                  The FutureSec Summit on October 30ᵗʰ, 2024, in Dubai under the
                  theme “Fortifying Cyber Resilience Amidst Evolving Threats” will
                  convene leading cybersecurity experts, industry leaders, and
                  innovators to explore the latest trends, challenges, and
                  advancements. 
                  <br />
                  <br />
                  Participants will gain valuable insights into the evolving threat
                  landscape and proactive measures to protect against cyber threats.
                  This summit is essential for anyone seeking to enhance their
                  cybersecurity posture in a rapidly changing digital world.
                  <br />
                  <br />
                </b>
                <Button
                  className="px-3 py-2 rounded-0"
                  color="primary"
                  href="/registration/download-agenda"
                >
                  <b className="m-0 text-600" style={{ color: "#fff", letterSpacing: '1px', textTransform: 'none', fontSize: '1rem', }}>
                    Download Agenda
                  </b>
                </Button>
                &nbsp;&nbsp;
                <Button
                  className="px-3 py-2 rounded-0"
                  style={{ background: '#f5f5f5', borderColor: '#fff' }}
                  href="/registration/download-brochure"
                >
                  <b className="m-0 text-600" style={{ color: "#000", letterSpacing: '1px', textTransform: 'none', fontSize: '1rem', }}>
                    Download Brochure
                  </b>
                </Button>
              </h3>
            </Col>
            <Col lg={6}>
              <div className="shadow">
                <img
                  src={require("assets/images/homepage.jpg")}
                  style={{ borderRadius: '10px' }}
                  width="100%"
                  alt="homepage"
                />
              </div>
              {/* <img
                src={require(`assets/images/homepage.jpg`)}
                width="100%"
                alt="Finnovex"
              /> */}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={11} className="mt-5">
              <Slider {...settings}>
                {content.map((c) => (
                  <a href={c.link} key={c.link}>
                    <img
                      src={require(`assets/banners/${c.image}`)}
                      width="100%"
                      // style={{ maxWidth: "728px" }}
                      alt=""
                    />
                  </a>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  // { link: "https://www.linkedin.com/showcase/futuresecksa/ ", image: "1.jpg" },
  // { link: "https://www.sophos.com/en-us/labs/security-threat-report.aspx?cmp=104846", image: "2.png" },
  // { link: "https://www.youtube.com/watch?v=oFLXD_JDvZs", image: "3.jpg" },
];

export default TextGrid;
