import React from "react";

import { Container, Row, Col, Card } from "reactstrap";

// import Slider from "react-slick";

function Sponsor({ sponsors }) {
  // const settings = {
  //   dots: true,
  //   autoplay: true,
  //   arrows: true,
  //   autoplaySpeed: 4000,
  //   slidesToShow: 4,
  //   slidesToScroll: 4,
  //   className: "p-10",
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          // backgroundImage: "linear-gradient(315deg, #d4418e 0%, #0652c5 74%)",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h1 className="text-center section-title">
                <b>2020 SPONSORS & PARTNERS</b>
              </h1>
            </Col>

            {/* <Col lg={12} xs={11} className={"ml-auto mr-auto"}> */}
            {/* <Slider {...settings}> */}
            {sponsors.map((sponsor, index) => (
              <Col lg={3} key={index}>
                <a href={`/sponsors/${sponsor.pageLink}`}>
                  <Container fluid>
                    <Row>
                      <Col
                        lg={12}
                        className="text-center text-dark border-bottom mb-2"
                        style={{
                          padding: "1rem 0rem",
                        }}
                      >
                        <b></b>
                        <h4
                          className="text-dark text-400 text-uppercase"
                          style={{ margin: 0, padding: 0 }}
                          dangerouslySetInnerHTML={{
                            __html: sponsor.type,
                          }}
                        ></h4>
                      </Col>
                      <Col lg={12}>
                        <Card style={{ borderRadius: "0" }}>
                          <img
                            src={require(`assets/sponsors/${sponsor.image}`)}
                            alt={sponsor.name}
                            width="100%"
                            style={{ borderRadius: "0" }}
                          />
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </a>
              </Col>
            ))}
            {/* </Slider> */}
            {/* </Col> */}
          </Row>
          {/* <Row>
            <Col lg={12} className={"text-center"}>
              <Button
                href="/sponsor/sponsors-partners"
                className="btn mt-5"
                color="primary"
                size="lg"
              >
                <p className="m-0 text-700">VIEW ALL</p>
              </Button>
            </Col>
          </Row> */}
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
