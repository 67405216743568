import React from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Input,
  Form,
  Alert,
  Spinner,
} from "reactstrap";
import { Redirect } from "react-router-dom";


function Register({ type, successMsg }) {
  const urlParams = new URLSearchParams(window.location.search);
  let utm_source = urlParams.get("utm_source");
  if (utm_source) {
    // all good
  } else {
    utm_source = 'Direct';
  }
  const utm_medium = urlParams.get("utm_medium");
  const utm_campaign = urlParams.get("utm_campaign");

  const [details, setDetails] = React.useState({
    type,
    consent: 'No',
    utm_source,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    company: '',
    country: '',
    job: '',
    industry: '',
    utm_medium,
    utm_campaign,
    marketing: 'No',
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);

  const onChangeDetails = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);
    if (type === "REPORT" || type === "EBOOK") {
      const reg = /[a-zA-Z0-9]*@[gmail|yahoo|hotmail]+\.[com|co.in]+/;
      if (reg.test(details.email)) {
        setLoading(false);
        setError("Please enter your business email only");
        return null;
      }
    }
    // https://us-central1-futuresec.cloudfunctions.net/api/email
    fetch("https://api.khaleejtimesevents.com/futuresec/rg.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    })
      .then((res) => {
        console.log(res);
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setLoading(false);
        // setSuccess(
        //   successMsg
        //     ? successMsg
        //     : "Thank you for your request. We'll get back to you shortly."
        // );
        setRedirect(true);
      })
      .catch((err) => {
        setLoading(false);
        setError("Something went wrong please try again");
      });
  };
  let inputs = content;
  if (type === "BROCHURE") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5, 6].includes(c.id));
  }
  if (type === "REPORT") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5, 6].includes(c.id));
  }
  if (type === "EBOOK") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5, 6].includes(c.id));
  }
  if (type === "SPONSOR") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5, 6, 7].includes(c.id));
  }
  if (type === "MEDIA") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5].includes(c.id));
  }
  if (redirect) {
    return <Redirect to={`/thank-you/${type}`} />;
  }

  return (
    <div
      style={{
        paddingTop: "1rem",
        backgroundColor: "#0b233d",
        // backgroundImage:
        //   "linear-gradient(90deg, rgba(234,208,241,1) 0%, rgba(205,243,248,1) 100%)",
        overflow: "hidden",
      }}
    >
      <Container>
        <Row>
          <Col className="mx-auto mb-5 py-5" lg={8}>
            <Container className="my-auto text-main text-white text-400">
              {type === "REGISTER" && (
                <p>
                  The event will provide you with the latest market dynamics and trends related to security technology and is carefully curated for cybersecurity professionals only. Free passes are not applicable to solution providers and consultants.
                </p>
              )}
              <Form onSubmit={onSubmitForm} className="register-form">
                <Row className="justify-content-center">
                  {inputs.map((i) => (
                    <Col lg={6} className="my-2" key={i.name}>
                      <label>{i.placeholder}*</label>
                      <Input
                        placeholder={i.placeholder}
                        type={i.type}
                        name={i.name}
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>
                  ))}
                  {(type.toLowerCase() === "SPEAKER".toLowerCase() || type.toLowerCase() === "SPONSOR".toLowerCase() || type.toLowerCase() === "REGISTER".toLowerCase()) ? (
                    <Col lg={6} className="my-2">
                      {/* <label>PricePlan*</label>
                      <Input
                        type="select"
                        name="priceplan"
                        id="priceplan"
                        onChange={onChangeDetails}
                        required
                      >
                        <option value="">SELECT</option>
                        <option>FREE</option>
                        <option>AED 350</option>
                      </Input> */}
                      <label>Industry *</label>
                      <Input
                        type="select"
                        name="industry"
                        id="industry"
                        onChange={onChangeDetails}
                        required
                      >
                        <option value="">SELECT</option>
                        {industries.map((industry) => (
                          <option key={industry}>{industry}</option>
                        ))}
                      </Input>
                    </Col>
                  ) : ''}

<Col lg={12} className="text-left pt-2">
                    <p className="text-600" style={{ textTransform: "none" }}>
                      <input
                        type="checkbox"
                        name='marketing'
                        onChange={(e) => {
                          if (e.target.checked) {
                            setDetails({ ...details, marketing: 'Yes' });
                          } else {
                            setDetails({ ...details, marketing: 'No' });
                          }
                        }}
                      />
                      &nbsp;
                      I would like to receive marketing communications on products, services or events.
                    </p>
                  </Col>

                  <Col lg={12} className="text-left pt-2">
                    <p className="text-600" style={{ textTransform: "none" }}>
                      <input
                        type="checkbox"
                        name='consent'
                        required
                        onChange={(e) => {
                          if (e.target.checked) {
                            setDetails({ ...details, consent: 'Yes' });
                          } else {
                            setDetails({ ...details, consent: 'No' });
                          }
                        }}
                      />
                      &nbsp;
                      I provide consent to Galadari Printing and Publishing to process my personal data in line with the{" "}
                      <a href="https://www.khaleejtimes.com/privacy-notice" className="text-600 text-white">Privacy Notice</a>
                    </p>
                  </Col>
                  
                </Row>

                {loading ? (
                  <span>
                    <Spinner className="mt-3" color="warning" size="sm" />
                  </span>
                ) : (
                  <Button
                    block
                    className="btn text-white text-700 mt-3"
                    color="info"
                    size="lg"
                    type="submit"
                    disabled={loading || details.consent !== 'Yes'}
                  >
                    Submit
                  </Button>
                )}
              </Form>
            </Container>
            <CreateAlert success={success} error={error} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function CreateAlert({ success, error }) {
  const color = success ? "success" : "danger";
  const message = success ? success : error;
  if (!success && !error) {
    return "";
  } else {
    return (
      <Alert
        color={color}
        isOpen={true}
        fade={true}
        className="text-center"
        style={{ marginTop: "1rem" }}
      >
        {message}
      </Alert>
    );
  }
}

export default Register;

const content = [
  { id: 1, name: "first_name", type: "text", placeholder: "First Name" },
  { id: 2, name: "last_name", type: "text", placeholder: "Last Name" },
  { id: 3, name: "email", type: "email", placeholder: "Business Email" },
  { id: 4, name: "phone", type: "text", placeholder: "Mobile" },
  { id: 5, name: "company", type: "text", placeholder: "Company" },
  { id: 6, name: "job", type: "text", placeholder: "Job Title" },
  { id: 7, name: "country", type: "text", placeholder: "Country" },
  // { id: 8, name: "nationality", type: "text", placeholder: "Nationality" },
];

const industries = [
  "Aerospace",
  "Agriculture",
  "Automotive",
  "Banking/Finance",
  "Construction",
  "CX & UX",
  "Emergency Services",
  "Energy & Utilities",
  "Environment, Mining & Chemicals",
  "Government",
  "Healthcare",
  "ISP",
  "Investors/VC",
  "Manufacturing",
  "Oil & Gas",
  "Public Safety",
  "Publishers",
  "Retail/e-Commerce",
  "Sales & Marketing",
  "Technology",
  "Other",
];
