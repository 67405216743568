import React from "react";

import { Container, Row, Col } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";

const content = [
  { title: "Cyber Threat Intelligence", image: "/images/keyHigh-1.svg" },
  { title: "Zero Trust Security", image: "/images/keyHigh-2.svg" },
  { title: "Cloud Security", image: "/images/keyHigh-3.svg" },
  { title: "AI and Machine Learning in Cybersecurity", image: "/images/keyHigh-4.svg" },
  { title: "The Role of Women in Cybersecurity", image: "/images/keyHigh-5.svg" },
  { title: "Incident Response and Recovery", image: "/images/keyHigh-6.svg" },
  { title: "Blockchain and Cybersecurity.", image: "/images/keyHigh-7.svg" },
  { title: "Emerging Threats and Future Trends", image: "/images/keyHigh-8.svg" },
  { title: "DevSecOps- Development, Security and Operations", image: "/images/keyHigh-9.svg" },
  { title: " IoT", image: "/images/keyHigh-10.svg" },
];

function KeyNotes() {
  return (
    <>
      <div className="section py-5" style={{ background: "transparent" }}>
        <Container>
          <Row className="justify-content-start">
            <Col className="ml-auto mr-auto " md={12}>
              <h1 className="title mb-4 section-title text-center">
                <b style={{ color: 'rgb(87, 200, 232)' }}>Key Highlights</b>
              </h1>
              <br />
            </Col>
            {content.map((data, index) => (
              <Col lg={4} xs={6} key={index} className="pb-3 pb-md-4 pb-lg-5 px-0 col-12 col-md-6 col-lg-4">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOnce={true}
                  duration={0.5}
                >
                  <Container fluid>
                    <Row>
                      <Col xs={3} className="mb-1 align-self-center">
                        <img
                          src={data.image}
                          alt=""
                          width="100%"
                          style={{ maxWidth: "75px" }}
                        />
                      </Col>
                      <Col
                        xs={9}
                        className="align-self-center"
                        style={{
                          borderLeft: "3px solid #a4508b",
                        }}
                      >
                        <h3 className="text-600 text-white">{data.title}</h3>
                      </Col>
                    </Row>
                  </Container>
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default KeyNotes;
