import React from "react";

import { Container, Row, Col, Card, Modal } from "reactstrap";

import ScrollAnimation from "react-animate-on-scroll";

import SpeakerModal from "components/SpeakerModal.js";



function SpeakerGrid({ speakers }) {

  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });

  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  function SpeakerCard({ speaker }) {
    return (
      <a href="/" onClick={(e) => updateModal(e, speaker)}>
        <Card
          className="card-background-image mx-auto p-3"
          style={{
            borderRadius: 0,
            boxShadow: "none",
            background: "transparent",
          }}
        >
          <div className='d-flex flex-column align-items-center'>
            <div className="speaker-image border rounded-circle mb-3 shadow-sm">
              <img src={speaker.image} className="speaker-image-img rounded-circle" alt="speaker" />
            </div>

            <div className="speakerName text-center text-dark">{speaker.name}</div>
            <div className="speakerTitle text-center">{speaker.title}</div>
            <div className="speakerCompany text-center">{speaker.company}</div>
          </div>
        </Card>
      </a>
    );
  }

  return (
    <>
      <div
        style={{
          // backgroundColor: "#0b233d",
          backgroundColor: "",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <Row>
            {speakers.map((speaker, index) => (
              <Col className="ml-auto mr-auto px-1 mb-5" xs={12} sm={6} lg={4} key={index}>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOnce={true}
                  duration={0.5}
                >
                  <SpeakerCard speaker={speaker} />
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Container>

        <Modal isOpen={open.open} toggle={(e) => updateModal(e)} style={{}}>
          <div className="">
            <button aria-label="Close" className="close pt-3 pr-4" data-dismiss="modal" type="button" onClick={(e) => updateModal(e)} >
              <span aria-hidden={true}>×</span>
            </button>
            <SpeakerModal speaker={open.speaker} />
          </div>
        </Modal>
      </div>
    </>
  );
}

export default SpeakerGrid;
