import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import SpeakerCardAll from "components/SpeakerCardAll";
import { speakers } from "./content";
import Footer from "components/Footer.js";
// import Sponsor from "components/Sponsor.js";
// import { sponsors } from "./content";
// import Testimonials from "components/Testimonials.js";

function SponsorPage() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="SPEAKERS 2024" bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <SpeakerCardAll speakers={speakers} />
      </div>
      {/* <Testimonials /> */}
      {/* <Sponsor sponsors={sponsors} /> */}
      <Footer />
    </>
  );
}

export default SponsorPage;
