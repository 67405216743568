import React from "react";

import { Container, Row, Col, Button } from "reactstrap";

import ScrollAnimation from "react-animate-on-scroll";

function WhySponsor() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth > 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section section-with-space border-top border-bottom py-3"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12} className={"text-center"}>
              <Button href="/sponsor/sponsorship-enquiry" className="" color="primary" size="lg" >SPONSOR ENQUIRY</Button>
              {" "}
              {/* <Button href="/sponsor/download-report" className="" color="danger" size="lg" >DOWNLOAD POST-SHOW REPORT</Button> */}
              {" "}
              <Button href="/sponsor/download-brochure" className="" color="primary" size="lg" >DOWNLOAD BROCHURE</Button>
            </Col>
            {content.map((data, index) => (
              <Col lg={6} xs={12} key={index} className="py-5 text-center">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOnce={true}
                  duration={0.5}
                >
                  <div
                    className="p-2"
                    style={{
                      background:
                        "linear-gradient(94deg, rgba(25,70,121,1) 9%, rgba(14,38,63,1) 75%)",
                      borderRadius: "10px",
                      border: "1px solid black",
                    }}
                  >
                    <h2 className="text-700 text-white text-uppercase m-0">
                      <img
                        src={require(`assets/images/icons/${data.image}`)}
                        style={{ maxWidth: "50px" }}
                        alt="dataimage"
                      />{" "}
                      {data.title}
                    </h2>
                  </div>
                  <h3 className="text-400 pt-2">{data.description}</h3>
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div
        style={{
          backgroundColor: "#016cad",
        }}
        className="py-2"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h1 className=" text-center text-700 p-2 text-white">
                THE POWER OF ONLINE PRESENCE
              </h1>
            </Col>
            {stats.map((c, i) => (
              <Col
                lg={3}
                xs={3}
                key={i}
                className="text-center pt-2 text-white"
              >
                <h1
                  className="text-700 d-inline mt-0"
                  style={{ fontSize: size ? "3rem" : "2rem" }}
                >
                  {c.value}
                  {c.plus && "+"}
                </h1>
                <h1
                  className="text-400 mt-0"
                  style={{ fontSize: size ? "1.5rem" : "1rem" }}
                >
                  {c.title}
                </h1>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  {
    title: "Business continuity",
    image: "16.png",
    description: `While the world is traversing unfamiliar terrain
    and businesses transition to digital platforms,
    solution providers are at lack of opportunities
    to help them meet their cybersecurity needs.
    FutureSec Virtual helps you ensure that you
    are still reaching out to your target audience
    while generating leads.`,
  },
  {
    title: "Targeted matchmaking and leads",
    image: "15.png",
    description: `An elaborate setup for the virtual conference
      means that the pre-registered delegates are
      available to discuss new opportunities and
      solutions to help meet their remote working
      setup needs and the event platform helps
      you introduce and set up discussions.`,
  },
  {
    title: "The rise of remote workforce",
    image: "14.png",
    description: `With over 80% of the organizations in the Gulf
      looking at remote working as a solution in the
      current scenario and as a long term strategy,
      the need for right infrastructure enabled by
      technology is more important than ever.`,
  },
  {
    title: "Cross-border delegation ",
    image: "13.png",
    description: `Being a virtual conference, the delegates across
      GCC will be attending this forum to help the
      enable the next phase of workforce development
      across distributed locations without affecting
      their processes.`,
  },
  {
    title: "360 branding & visibility",
    image: "13.png",
    description: `In these times of uncertainty ROI on your
    investment is the key mantra. Khaleej Times
    will help you meet you branding and marketing
    needs through a 360 degree marketing strategy
    across print, digital and social media over the
    next 3 months reaching out to over 4 million+
    audience in GCC.
    `,
  },
];
const stats = [
  { title: "Monthly Page Views", value: "30000", plus: true },
  { title: "Newsletter Subscribers", value: "250000", plus: true },
  { title: "Million Hours of Content", value: "3.5 ", plus: true },
];

export default WhySponsor;
